.pre_loader_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
}

.pre_loader_container .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.pre_loader_container .modal_img {
    position: fixed;
    left: 4.2rem;
    bottom: 2rem;
    display: flex;
    max-width: 75vw;
    max-height: 75vh;
    width: auto;
    height: auto;
    z-index: 2;
}

.rr_loader_con {
    position: fixed;
    right: 12rem;
    bottom: 10rem;
    z-index: 1;
}

.footer_rr310_loader {
    position: absolute;
    bottom: 0rem;
    left: 1.6rem;
    display: flex;
    flex-direction: row;
    z-index: 3;
}

.footer_rr310_loader .parallelograms {
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
    padding: 1rem 0.5rem;
}

.footer_rr310_loader .parallelograms div {
    width: 10rem;
    height: 2.8rem;
    transform: skew(-20deg);
    margin: 1rem 1rem;
}

.footer_rr310_loader .parallelograms img {
    display: block;
    max-width: 10rem;
    max-height: 2.8rem;
    width: auto;
    height: auto;
    cursor: pointer;
}