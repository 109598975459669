@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./fonts/Gilroy-Medium.ttf) format('truetype');
}

.lightFont {
  font-family: "Gilroy-Light";
}

.mediumFont {
  font-family: "Gilroy-Medium";
}

.boldFont {
  font-family: "Gilroy-Bold";
}

body {
  margin: 0;
  overflow: hidden;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  width: 80%;
  margin: auto;
}

canvas {
  width: 100%;
  height: 100%;
}

.loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  transition: opacity 1s linear;
  box-sizing: content-box;
}

#sidebar {
  position: absolute;
  overflow-y: auto;
  /* height: 100vh; */
  bottom: 1%;
  width: 100%;
  text-align: center;
}

#sidebar img {
  padding: 10px;
  border-radius: 50%;
}

#sidebar img:hover {
  cursor: pointer;
  padding: 5px;
  border: 5px solid #3d3f44;
}

.btn {
  background-color: #fff;
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  bottom: 2%;
  right: 2%;
}

.btn img {
  width: 50px;
}

.btn:hover {
  border: 2px solid #3d3f44;
  padding: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}