.container-pnf {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.heading-text {
  font-size: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #000000;
}

.bike-img img {
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  display: block;
  max-width: 22.5rem;
  max-height: 15.125rem;
  width: auto;
  height: auto;
}

.sub-Heading {
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #000000;
  margin-top: 3.886rem;
}