.App {
  font-family: sans-serif;
  text-align: center;
}

#warning-message {
  display: none;
}

@keyframes blink {
  50% {
    background-color: #a0a0a0;
  }
}

@media only screen and (orientation: portrait) {
  #wrapper {
    display: none;
  }

  #warning-message {
    display: block;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../src/assets/rotate-your-screen.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #warning-message p {
    width: 80vw;
    padding: 40rem 2rem 0.5rem 2rem;
    text-align: center;
    /* font-family: "Sitka", sans-serif;
    font-style: normal;
    font-weight: 500; */
    font-size: 2.5rem;
    line-height: 3.5rem;
    letter-spacing: 0rem;
    color: #000000;
    opacity: 0.8;
  }
}

@media screen and (orientation: landscape) {
  #warning-message {
    display: none;
  }
}

@media (min-width: 320px) {

  html,
  body {
    font-size: 5px;
  }
}

@media (min-width: 481px) {

  html,
  body {
    font-size: 6px;
  }
}

@media (min-width: 641px) {

  html,
  body {
    font-size: 7px;
  }
}

@media (min-width: 799px) {

  html,
  body {
    font-size: 9px;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {

  html,
  body {
    font-size: 10px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {

  html,
  body {
    font-size: 12px;
  }
}

@media (min-width: 1281px) and (max-width: 1536px) {

  html,
  body {
    font-size: 16px;
  }
}

@media (min-width: 1537px) and (max-width: 1792px) {

  html,
  body {
    font-size: 18px;
  }
}

@media (min-width: 1793px) and (max-width: 2048px) {

  html,
  body {
    font-size: 20px;
  }
}

@media (min-width: 2049px) and (max-width: 2560px) {

  html,
  body {
    font-size: 22px;
  }
}

@media (min-width: 2561px) and (max-width: 3840px) {

  html,
  body {
    font-size: 24px;
  }
}