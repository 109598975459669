.loader_container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader_container img {
  display: block;
  width: auto;
  height: auto;
}